<template>
  <div class="writerefund">
    <title-top>填写退货物流</title-top>
    <div class="mt16"></div>
    <div class="row between align-center bg-white mb16" v-for="(item,i) in res.order.items" :key="i">
      <img class="imgsize176 br4 ml25 mr16 mt25 mb25" :src="item.image_url" alt />
      <div class="flex1 column font24 f-333333 mt25 mb25">
        <p class="mr25 ellipsis">{{item.product_name}}</p>
        <div class="row between f-999999 mt16 align-center">
          <span>{{item.specification_values | spec}}</span>
          <span class="ml25 mr25">×{{item.quantity}}</span>
        </div>
      </div>
    </div>
    <div class="row between align-center font28 f-1A1A1A bg-white h96 mb16">
      <div class="row">
        <span class="ml25">物流公司：</span>
        <input class="f-999999" placeholder="请选择"/>
      </div>
      <img v-if="false" class="imgsize24 mr25" src="~images/zyimg/nextbtn.png" alt />
    </div>
    <div class="row align-center font28 f-1A1A1A bg-white h96 mb16">
      <span class="ml25">物流单号：</span>
      <input class="flex1 f-999999 mr25" placeholder="单行输入" type="text" v-model="express"/>
    </div>
    <div class="row align-center font28 f-1A1A1A bg-white h96 mb16">
      <span class="ml25">联系电话：</span>
      <input class="flex1 f-999999 mr25" placeholder="请输入联系电话" type="text" v-model="sender_number"/>
    </div>
    <div class="row align-center font28 f-1A1A1A bg-white h96 mb16">
      <span class="ml25">备注说明：</span>
      <input class="flex1 f-999999 mr25" placeholder="选填" type="text" v-model="remark"/>
    </div>
    <div class="column just-center bg-white">
      <span class="font28 f-1A1A1A ml25 mt25">上传凭证</span>
      <div class="row align-center flex-wrap mb25">
        <van-uploader v-model="fileList" :after-read="afterRead" class="ml25 mt25" />
      </div>
    </div>
    <div class="row flex-center" @click="onSure">
      <div class="btn-red mt170" style="background-color:#FF8E61;">提交</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
import { formatDate } from "common/util";
import {refundsDetail,editApply} from 'network/igou2'
import Vue from "vue";
import axios from "axios";
import { Uploader } from "vant";
Vue.use(Uploader);
export default {
  name: "WriteRefund",
  data() {
    return {
      id:this.$route.query.id,
      res:{order:{items:[]}},
      credentials: [],
      fileList: [],
      express:'',
      tracking_number:'',
      sender_number:'',
      remark:''
    };
  },
  mounted() {
    this._refundsDetail();
  },
  methods:{
    _editApply(){
      let formData = new FormData();
      formData.append("refund_id",this.id);
      formData.append("express", this.express);
      formData.append("tracking_number", this.tracking_number);
      formData.append("sender_number", this.sender_number);
      formData.append("remark", this.remark);
      this.credentials.forEach(item => {
        formData.append("credentials[]", item);
      });

      let base_url = params.debug ? params.api_test_url : params.api_base_url;
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          //添加请求头
          Authorization: token,
          "Content-Type": "multipart/form-data",
          Accept: "application/json"
        }
      };
      console.log(formData);
      axios.post(base_url +  'refunds/'+this.id+'/logistics', formData, config).then(
        res => {
          console.log(res);
          this.$toast.show("提交成功");
          //this.$router.push({path:'/zyrefundhandle',query:{id:res.data.data.id}});
          this.$router.go(-2);
        },
        err => {
          this.$toast.show(err.response.data.error);
        }
      );
      editApply(this.id).then(res=>{
        console.log(res)
      })
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      this.credentials = [];
      this.fileList.forEach(item => {
        this.credentials.push(item.file); //第一个参数字符串可以填任意命名，第二个根据对象属性来找到file
      });
    },
    _refundsDetail(){
      refundsDetail(this.id).then(res=>{
        console.log(res)
        this.res = res.data;
      })
    },
    onSure(){
      if(!this.express){
        this.$toast.show('请填写物流公司');
        return;
      }
      if(!this.tracking_number){
        this.$toast.show('请填写物流单号');
        return;
      }
      if(!this.sender_number){
        this.$toast.show('请填写退货人电话');
        return;
      }
      this._editApply();
    }
  },
  filters: {
    formatDate(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    spec(specification_values) {
      let str = '';
      specification_values.forEach(item => {
        str += item.content+","
      });
      if(str){
        str.substring(0,str.length-1);
      }
      return str;
    },
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
</style>
